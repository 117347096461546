var exports = {};
var abs = Math.abs,
    min = Math.min;

function almostEqual(a, b, absoluteError, relativeError) {
  var d = abs(a - b);

  if (d <= absoluteError) {
    return true;
  }

  if (d <= relativeError * min(abs(a), abs(b))) {
    return true;
  }

  return a === b;
}

almostEqual.FLT_EPSILON = 1.1920929e-7;
almostEqual.DBL_EPSILON = 2.220446049250313e-16;
exports = almostEqual;
export default exports;